import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Imprint from "./Imprint";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route index element={<App />} />
        <Route path="imprint" element={<Imprint />} />
        <Route
          path="zanecraft"
          Component={() => {
            window.location.href = "https://zanecraft.net";
            return null;
          }}
        />
        <Route
          path="discord"
          Component={() => {
            window.location.href = "https://discord.gg/zane";
            return null;
          }}
        />
        <Route
          path="youtube"
          Component={() => {
            window.location.href = "https://youtube.com/zanety";
            return null;
          }}
        />
        <Route
          path="spotify"
          Component={() => {
            window.location.href =
              "https://open.spotify.com/playlist/0jcacc6AKk4TiDYlbXrxzN";
            return null;
          }}
        />
        <Route
          path="*"
          Component={() => {
            window.location.href = "/";
            return null;
          }}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
