import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="flex-initial w-full bg-dbgcolor-light dark:bg-dbgcolor-dark">
      <div className="flex flex-col items-center gap-3 max-w-screen-xl mx-auto p-6 sm:flex-row sm:justify-between">
        <div className="w-60 text-center sm:text-left">
          <Link
            className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
            to="/"
          >
            Home
          </Link>
        </div>
        <div className="w-60 text-md text-center sm:w-full sm:text-lg sm:text-center">
          Copyright © {new Date().getFullYear()} by zane
        </div>
        <div className="w-60 text-center sm:text-right">
          <Link
            className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
            to="/imprint"
          >
            Impressum
          </Link>
        </div>
      </div>
    </footer>
  );
}
