export default function App({
  link,
  title,
  subtitle,
  text,
  image,
  reverse = false,
}: {
  link: string;
  title: string;
  subtitle: string;
  text: string;
  image: string;
  reverse: boolean;
}) {
  return (
    <a href={link}>
      <div
        className={
          "flex flex-col rounded-2xl overflow-hidden bg-lbgcolor-light shadow dark:bg-lbgcolor-dark " +
          (reverse ? "lg:flex-row-reverse" : "lg:flex-row")
        }
      >
        <div className="flex flex-col flex-auto p-6 sm:p-8 lg:w-1/2 lg:p-10">
          <div className="mb-4 font-display text-4xl font-bold sm:mb-6 sm:text-6xl lg:text-7xl">
            {title}
            <br />
            <span className="text-accent-light dark:text-accent-dark">
              {subtitle}
            </span>
          </div>
          <div className="text-lg sm:text-xl sm:leading-relaxed">{text}</div>
        </div>
        <div className="flex-auto lg:w-1/2">
          <img
            className="w-full aspect-video max-h-80 object-cover lg:h-full lg:max-h-none"
            src={image}
            alt=""
          />
        </div>
      </div>
    </a>
  );
}
