import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="fixed top-0 z-50 w-full h-16 bg-bgcolor-light shadow-md dark:bg-dbgcolor-dark">
      <div className="flex flex-row justify-between gap-8 h-full max-w-screen-xl mx-auto px-4 sm:px-6">
        <div className="flex flex-row items-center gap-8 select-none">
          <Link
            className="font-display text-2xl font-semibold text-accent-light dark:text-accent-dark sm:text-3xl"
            to="/"
          >
            zaneunity
          </Link>
        </div>
        <div className="hidden flex-row items-center gap-8 select-none sm:flex">
          <a
            className="font-display text-xl transition-colors hover:text-accent-light dark:hovertext-accent-dark"
            href="https://zanecraft.net"
          >
            Zanecraft
          </a>
          <a
            className="font-display text-xl transition-colors hover:text-accent-light dark:hovertext-accent-dark"
            href="https://discord.gg/zane"
          >
            Discord
          </a>
          <a
            className="font-display text-xl transition-colors hover:text-accent-light dark:hovertext-accent-dark"
            href="https://youtube.com/zanety"
          >
            YouTube
          </a>
        </div>
      </div>
    </header>
  );
}
