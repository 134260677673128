export default function Main({ children }: { children: any }) {
  return (
    <main className="flex flex-col flex-auto mt-16 bg-bgcolor-light text-black dark:bg-bgcolor-dark dark:text-white">
      <img
        className="w-full h-80 object-cover"
        src="/images/banner.jpg"
        alt="Banner"
      />
      <article className="flex flex-col gap-4 max-w-screen-xl mx-auto p-4 sm:gap-6 sm:p-6">
        {children}
      </article>
    </main>
  );
}
